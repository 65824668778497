@charset "UTF-8";
#loading {
  display: none;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
}

.load {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load 1.1s infinite ease;
  transform: translateZ(0);
}

#loading.hidden {
  animation: loadhidden 1s ease;
  animation-fill-mode: forwards;
}

@keyframes loadhidden {
  0% {
    opacity: 1;
  }
  99.9% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none !important;
  }
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* ==============================
		  common.scss
================================= */
/*---------------------------------------------------------
	全ページ共通スタイル
---------------------------------------------------------*/
* {
  box-sizing: border-box;
}

body {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: “palt” 1;
  font-stretch: 95%;
  line-height: 1.9;
  color: #1c1c1c;
  text-align: justify;
  letter-spacing: .05em;
  background-color: #fafafa;
  line-break: normal;
  font-size: 14px;
}

@media screen and (min-width: 960px) {
  body {
    font-size: 16px;
    overflow-x: hidden;
  }
}

body.android {
  font-family: "Noto Serif JP", serif;
  font-weight: 400;
}

.inview_box {
  opacity: 0;
  transition: all 1000ms ease;
  position: relative;
  z-index: 1;
  top: 50px;
}

.inview_box_on {
  opacity: 1;
  top: 0px;
}

.mainInner {
  padding: 0 5%;
}

/* responsive */
.sp-inline,
.sp-block {
  display: none;
}

@media screen and (min-width: 560px) {
  .sp-none {
    display: none;
  }
  .sp-inline {
    display: inline;
  }
  .sp-block {
    display: block;
  }
}

.en {
  font-family: "Sorts Mill Goudy", serif;
}

#pageHeader {
  width: 100%;
  position: absolute;
  z-index: 20;
}

.menu {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
}

@media screen and (min-width: 1120px) {
  .menu {
    top: 28px;
    left: 28px;
  }
}

.menu .p-hamburger__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 34px;
  height: 2px;
  background-color: #fafafa;
  transition: all .2s ease;
}

.menu .p-hamburger__line::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: inherit;
  top: -9px;
}

.menu .p-hamburger__line::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: inherit;
  top: 9px;
}

.menu[aria-expanded="true"].scroll {
  background: inherit;
}

.menu[aria-expanded="true"] .p-hamburger__line {
  background-color: inherit !important;
}

.menu[aria-expanded="true"] .p-hamburger__line::before {
  top: 0;
  background-color: #1c1c1c;
  transition: all .3s ease;
  transform: rotate(45deg);
}

.menu[aria-expanded="true"] .p-hamburger__line::after {
  top: 0;
  background-color: #1c1c1c;
  transition: all .3s ease;
  transform: rotate(-45deg);
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 5 !important;
  background: #fafafa;
  color: #1c1c1c;
  overflow: hidden;
  transition: all .6s ease-in;
  opacity: 0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transform-origin: right top;
  display: flex;
  transform: translateY(-100vh);
}

.nav__list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0 0 0 32px;
  flex-flow: column;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  top: -1rem;
}

@media screen and (min-width: 960px) {
  .nav__list {
    top: 0rem;
  }
}

.nav__list .headline_sec::before {
  font-size: 24px;
}

@media screen and (min-width: 1120px) {
  .nav__list .headline_sec::before {
    font-size: 38px;
  }
}

.nav__list .langage {
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  margin-bottom: 24px;
  margin-top: 8px;
  width: calc(100% - 32px);
  justify-content: center;
}

.nav__list .langage li:not(:last-child) a {
  border-right: 1px solid #1c1c1c;
}

.nav__list .langage li a {
  color: #1c1c1c;
  display: inline;
}

.nav__list__items {
  display: none;
}

.nav__list__items.animated {
  display: block;
}

.nav__list__items a {
  display: block;
  width: calc(100vw - 3.2rem);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  color: #1c1c1c;
}

.nav__list__items a::after {
  content: "";
  background-image: url(../img/common/icons/menuarrow.svg);
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
}

.nav__list__items:nth-child(1) {
  animation-delay: 0ms;
}

.nav__list__items:nth-child(2) {
  animation-delay: 75ms;
}

.nav__list__items:nth-child(3) {
  animation-delay: 150ms;
}

.nav__list__items:nth-child(4) {
  animation-delay: 225ms;
}

.nav__list__items:nth-child(5) {
  animation-delay: 300ms;
}

.nav__list__items:nth-child(6) {
  animation-delay: 375ms;
}

.nav__list__items:nth-child(7) {
  animation-delay: 450ms;
}

.nav__blank {
  margin-top: 45px;
}

@media screen and (min-width: 1280px) {
  .nav__blank {
    margin: 0;
  }
}

.nav__blank a {
  justify-content: space-between;
  display: flex;
  background: #005bce;
  color: #fafafa;
  width: calc(100vw - 64px);
  align-items: center;
}

@media screen and (min-width: 1120px) {
  .nav__blank a {
    align-items: center;
    width: 250px;
    height: 70px;
    transition: all 0.5s 0s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

@media screen and (min-width: 1120px) {
  .nav__blank a:hover {
    transform: translateX(70px);
  }
}

.nav__blank a.recruit {
  background: #46a5d6;
  margin-top: 16px;
}

@media screen and (min-width: 1120px) {
  .nav__blank a.recruit {
    margin-top: 0;
  }
}

.nav__blank a.recruit::after {
  background-color: #58acd7;
}

.nav__blank a::after {
  content: "";
  padding: 0;
  width: 70px;
  height: 70px;
  background-color: #0f65d2;
  background-image: url(../img/common/icons/arrow.svg);
  background-size: 38px;
  background-position: center center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1120px) {
  .nav__blank a::after {
    padding: 0;
  }
}

.nav__blank__link {
  padding: 16px 8px;
  width: calc(100% - 70px);
  text-align: center;
}

.nav .reservation-block {
  width: 90%;
  padding: 24px 25px;
  justify-content: center;
}

@media screen and (min-width: 960px) {
  .nav .reservation-block {
    padding: 18px 32px;
    width: auto;
  }
}

.langage {
  position: absolute;
  top: 40px;
  right: 60px;
  display: none;
  font-size: 12px;
}

@media screen and (min-width: 960px) {
  .langage {
    display: flex;
  }
}

.langage li a {
  color: #fafafa;
  padding: 0 1em;
}

.langage li:not(:last-child) a {
  border-right: 1px solid #fafafa;
}

body.open {
  overflow: hidden;
}

body.open .nav {
  opacity: 1 !important;
  transition: all .6s ease-in;
  transform: translateY(0);
  animation-delay: .3s;
}

body.open .nav .p-drawer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform .3s ease-in-out;
  transform: translateX(0);
}

.u-visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
}

.reservation-block {
  padding: 17px 0;
  justify-content: center;
  background: #1c1c1c;
  color: #fafafa;
  position: relative;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 12;
  left: 0;
  width: 100%;
  cursor: pointer;
  cursor: hand;
}

@media screen and (min-width: 960px) {
  .reservation-block {
    padding: 18px 32px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: auto;
  }
}

.reservation-block::after {
  position: absolute;
  top: calc(50% - 4px);
  right: 24px;
  content: "";
  width: 8px;
  height: 8px;
  display: none;
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  transform: rotate(45deg);
  transition: all .3s ease;
}

.reservation-block .icon {
  width: 20px;
  height: 18px;
  margin-right: 1em;
  position: relative;
  top: -2px;
}

.section-access {
  background-color: #3c3c3f;
  color: #fafafa;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .section-access {
    flex-flow: row;
  }
}

.section-access .headline_sec.break {
  padding-top: 72px;
  margin-bottom: 29px;
}

.section-access__iframe {
  overflow: hidden;
}

.section-access__iframe #sample {
  width: 100vw;
  height: 100vw;
  filter: grayscale(100%);
  transition: all .3s ease;
}

@media screen and (min-width: 960px) {
  .section-access__iframe #sample {
    width: 50vw;
    height: 100%;
  }
}

.section-access__iframe #sample:hover {
  filter: grayscale(0%);
}

.section-access__text {
  padding: 36px 16px 36px 60px;
}

@media screen and (min-width: 960px) {
  .section-access__text {
    padding: 63px 54px;
    width: 50%;
  }
}

.section-access__text__box {
  margin-bottom: 12px;
}

.section-access__text .s-size {
  font-size: 10px;
  margin-bottom: 32px;
}

@media screen and (min-width: 960px) {
  .section-access__text .s-size {
    font-size: 15px;
  }
}

.section-access__text .m-size {
  font-size: .8em;
}

@media screen and (min-width: 960px) {
  .section-access__text .m-size {
    font-size: 13px;
  }
}

.section-access__text .l-size {
  font-size: 1.2em;
}

@media screen and (min-width: 960px) {
  .section-access__text .l-size {
    font-size: 20px;
  }
}

.section-access__text .l-size .s-size {
  margin-left: 1em;
}

@media screen and (min-width: 960px) {
  .section-access__text .l-size .s-size {
    font-size: 12px;
  }
}

.section-access__text .fb {
  font-weight: 700;
}

.footer_img {
  background-image: url(../img/home/footer_img@2x.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 37vw;
}

@media screen and (min-width: 960px) {
  .footer_img {
    height: 530px;
  }
}

.footer {
  background-color: #1c1c1c;
  color: #fafafa;
  padding: 45px 32px 90px;
}

@media screen and (min-width: 960px) {
  .footer {
    padding: 54px 32px 120px;
  }
}

.footer__inner {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 1332px;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .footer__inner {
    flex-flow: row;
    justify-content: space-between;
  }
}

.footer__logo {
  margin-bottom: .5em;
}

.footer__logo .logo {
  fill: #fafafa;
  width: 30vw;
  max-width: 195px;
  height: 14vw;
  max-height: 81px;
}

.footer__info {
  font-size: 12px;
}

@media screen and (min-width: 960px) {
  .footer__info {
    padding-top: 7px;
  }
}

.footer__info .adress {
  display: inline;
}

@media screen and (min-width: 960px) {
  .footer__info .adress {
    display: block;
  }
}

.footer__menu {
  display: none;
  font-size: 12px;
}

.footer__menu a {
  color: #fafafa;
  position: relative;
  opacity: 1;
  transition: all .3s ease;
}

.footer__menu a::after {
  content: "";
  width: 100%;
  position: absolute;
  top: 120%;
  left: 0;
  height: 1px;
  background: #fafafa;
  transition: all .3s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.footer__menu a:hover {
  opacity: .8;
}

.footer__menu a:hover::after {
  transform: scaleX(1);
}

@media screen and (min-width: 960px) {
  .footer__menu {
    padding-top: 7px;
    display: block;
  }
}

.footer__menu__list {
  display: flex;
  flex-wrap: wrap;
  width: 210px;
  flex-flow: column;
  justify-content: space-around;
}

@media screen and (min-width: 1120px) {
  .footer__menu__list {
    justify-content: space-between;
    flex-flow: row;
    min-width: 500px;
    width: 44vw;
    max-width: 568px;
  }
}

.footer__copy {
  font-size: 11px;
}

@media screen and (min-width: 960px) {
  .footer__copy {
    padding-top: 7px;
  }
}

.bottom {
  padding: 16px 0;
  background: #333;
  color: #fafafa;
  text-align: center;
}

.btn_cen {
  text-align: center;
}

.common_btn01 {
  position: relative;
  font-size: 16px;
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
  text-align: center;
  padding: 16px 40px 16px 32px;
  transition: all .3s ease;
  display: inline-block;
  z-index: 1;
}

.common_btn01::after {
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  border-top: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
  transform: rotate(45deg);
  transition: all .3s ease;
}

.common_btn01::before {
  transition: transform .3s ease;
  content: "";
  width: 100%;
  height: 100%;
  background: #1c1c1c;
  transform: scaleX(0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.common_btn01:hover {
  color: #fafafa;
}

.common_btn01:hover::after {
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.common_btn01:hover::before {
  transform: scaleX(1);
}

.common_btn01.white {
  border: 1px solid #fafafa;
  color: #fafafa;
}

.common_btn01.white::after {
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.common_btn01.white::before {
  background: #fafafa;
}

.common_btn01.white:hover {
  color: #1c1c1c;
}

.common_btn01.white:hover::after {
  border-top: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}

.common_btn02 {
  position: relative;
  font-size: 16px;
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
  text-align: center;
  padding: 16px 0 16px 32px;
  transition: all .3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 240px;
  margin: 0 auto;
  z-index: 1;
  background-color: #fafafa;
}

@media screen and (min-width: 960px) {
  .common_btn02 {
    width: 220px;
  }
}

.common_btn02::before {
  transition: transform .3s ease;
  content: "";
  width: 100%;
  height: 100%;
  background: #1c1c1c;
  transform: scaleX(0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.common_btn02 .toggle {
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  transition: all .3s ease;
  position: relative;
}

.common_btn02 .toggle::before {
  content: "";
  width: 20px;
  height: 1px;
  display: block;
  position: absolute;
  background: #1c1c1c;
  top: 50%;
  left: 10px;
  transition: all .3s ease;
}

.common_btn02 .toggle::after {
  content: "";
  width: 20px;
  height: 1px;
  display: block;
  position: absolute;
  background: #1c1c1c;
  top: 50%;
  left: 10px;
  transform: rotate(90deg);
  transition: all .3s ease;
}

.common_btn02 .toggle.on::after {
  transform: rotate(0);
}

.common_btn02:hover {
  color: #fafafa;
}

.common_btn02:hover::before {
  transform: scaleX(1);
}

.common_btn02:hover .toggle::before {
  background: #fafafa;
}

.common_btn02:hover .toggle::after {
  background: #fafafa;
  transform: rotate(0);
}

.common_btn02:hover .toggle.on::after {
  transform: rotate(90deg);
}

.common_btn02.white {
  border: 1px solid #fafafa;
  color: #fafafa;
}

.common_btn02.white::after {
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.common_btn02.white::before {
  background: #fafafa;
}

.common_btn02.white:hover {
  color: #1c1c1c;
}

.common_btn02.white:hover::after {
  border-top: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}

.pop_tenawan {
  position: fixed;
  background: rgba(25, 25, 25, 0.9);
  z-index: 190;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop_tenawan .headline_sec.break {
  position: relative;
  padding-top: 30px;
  margin-bottom: 8px;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan .headline_sec.break {
    margin-bottom: 100px;
    padding-top: 69px;
  }
}

.pop_tenawan form {
  max-width: 788px;
  width: 100%;
  padding: 24px;
}

.pop_tenawan .tenawan__form {
  display: flex;
}

.pop_tenawan__form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form {
    width: 100%;
  }
}

.pop_tenawan__form__list {
  width: 85px;
  margin-bottom: 16px;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__list {
    width: 103px;
    margin-bottom: 8px;
  }
}

.pop_tenawan__form__list:first-child {
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__list:first-child {
    width: 280px;
    margin-bottom: 8px;
  }
}

.pop_tenawan__form__list select {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 18px;
  padding: 4px 0 8px;
  min-width: 3em;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__list select {
    width: 80px;
    margin-right: 6px;
  }
}

.pop_tenawan__form__list .ym {
  width: 50%;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__list .ym {
    width: 180px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__list .d {
    width: 80px;
    margin-right: 0;
  }
}

.pop_tenawan__form__link a {
  color: #fff;
}

.pop_tenawan__form__link a::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  margin-right: 8px;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #ffffff;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan__form__btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }
}

.pop_tenawan .pop_tenawan__search {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan .pop_tenawan__search {
    margin-top: 30px;
    margin-bottom: 70px;
  }
}

.pop_tenawan .common_btn01 {
  width: 100%;
  margin-bottom: 8px;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan .common_btn01 {
    max-width: 240px;
  }
}

.pop_tenawan .common_btn01:not(:hover) img {
  filter: saturate(0%) brightness(9999%);
}

.pop_tenawan #tenawan_close {
  position: relative;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  cursor: hand;
}

@media screen and (min-width: 1120px) {
  .pop_tenawan #tenawan_close {
    top: 40px;
    right: 80px;
  }
}

.pop_tenawan #tenawan_close::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 40px;
  background: #fafafa;
  top: 50%;
  left: 0;
  transform: rotate(45deg);
}

.pop_tenawan #tenawan_close::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 40px;
  background: #fafafa;
  top: 50%;
  left: 0;
  transform: rotate(135deg);
}

.room_headline {
  position: relative;
  font-size: 25px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  margin: 110px 0 0;
  flex-flow: column;
  align-items: center;
  line-height: 1;
  z-index: 2;
}

@media screen and (min-width: 960px) {
  .room_headline {
    font-size: 30px;
  }
}

.room_headline::before {
  content: "type.";
  position: absolute;
  display: inline-block;
  top: -126px;
  left: calc(50% - 1em);
  width: auto;
}

@media screen and (min-width: 960px) {
  .room_headline::before {
    left: calc(50% - 1em);
    width: 100%;
  }
}

.room_headline::after {
  content: "00";
  font-family: "Sorts Mill Goudy", serif;
  position: absolute;
  display: inline-block;
  top: -106px;
  left: calc(50% - .5em);
  font-size: 100px;
  width: auto;
}

@media screen and (min-width: 960px) {
  .room_headline::after {
    font-size: 113px;
    left: calc(50% - .5em);
    width: 100%;
  }
}

.room_headline.first::after {
  content: "01";
}

.room_headline.second::after {
  content: "02";
}

.room_headline.third::after {
  content: "03";
}

.room_headline p:not(.kana) {
  position: relative;
}

.room_headline p:not(.kana)::before {
  content: "";
  width: 23px;
  height: 1px;
  display: block;
  position: absolute;
  top: -80px;
  left: calc(50% - 11px);
  background-color: #1c1c1c;
}

.room_headline .kana {
  margin-top: .5em;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 10px;
}

@media screen and (min-width: 960px) {
  .room_headline .kana {
    font-size: 14px;
  }
}

.left-fix {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  font-size: 12px;
  line-height: 1.333;
  position: fixed;
  top: 30vh;
  left: 21px;
  color: #fafafa;
  transition: all .3s ease;
  z-index: 1;
}

@media screen and (min-width: 1280px) {
  .left-fix {
    left: 42px;
  }
}

.left-fix .inline {
  display: inline;
}

@media screen and (min-width: 960px) {
  .left-fix .inline {
    display: block;
  }
}

.pagetop {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #fafafa;
  border-radius: 50%;
  overflow: hidden;
  z-index: 11;
}

@media screen and (min-width: 960px) {
  .pagetop {
    width: 80px;
    height: 80px;
  }
}

.pagetop::before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  border-left: 2px solid #1c1c1c;
  border-top: 2px solid #1c1c1c;
  transform: rotate(45deg);
  position: relative;
  top: 2px;
}

@media screen and (min-width: 960px) {
  .pagetop::before {
    width: 25px;
    height: 25px;
    top: 5px;
  }
}

.headline_sec {
  font-size: 12px;
  font-weight: 400;
}

@media screen and (min-width: 960px) {
  .headline_sec {
    font-size: 18px;
  }
}

.headline_sec::before {
  content: attr(data-ruby);
  font-family: "Sorts Mill Goudy", serif;
  font-size: 32px;
  font-weight: 400;
  margin-right: 7px;
}

@media screen and (min-width: 960px) {
  .headline_sec::before {
    margin-right: 29px;
  }
}

@media screen and (min-width: 960px) {
  .headline_sec::before {
    font-size: 64px;
  }
}

.headline_sec.break {
  position: relative;
  padding-top: 32px;
}

.headline_sec.break::before {
  position: absolute;
  top: -15px;
  left: 0;
}

@media screen and (min-width: 960px) {
  .headline_sec.break::before {
    top: -55px;
  }
}

@media screen and (min-width: 1120px) {
  .headline_sec.break::before {
    top: -15px;
  }
}

.section-inner {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 820px;
}

@media screen and (min-width: 1280px) {
  .section-inner {
    max-width: 1100px;
  }
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c1c1c;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: 104px;
  color: #1c1c1c;
}

.close-icon::before {
  content: "";
  width: 32px;
  height: 1px;
  background: #fafafa;
  transform: rotate(45deg);
  position: absolute;
  top: auto;
  left: auto;
}

.close-icon::after {
  content: "";
  width: 32px;
  height: 1px;
  background: #fafafa;
  transform: rotate(-45deg);
  position: absolute;
  top: auto;
  left: auto;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
  top: -90px;
  max-height: 700px;
}

@media screen and (min-width: 960px) {
  .swiper-container {
    top: 0;
  }
}

.swiper-container .swiper-slide {
  overflow: hidden;
}

.swiper-container .swiper-button-next,
.swiper-container .swiper-container-rtl .swiper-button-prev {
  background-image: url(../img/common/arrow01.svg);
  background-color: #fafafa;
  border-radius: 50%;
  background-position: center center;
  background-size: 40%;
  width: 10vw;
  height: 10vw;
  max-width: 79px;
  max-height: 79px;
}

.swiper-container .swiper-button-prev,
.swiper-container .swiper-container-rtl .swiper-button-next {
  background-image: url(../img/common/arrow01.svg);
  background-color: #fafafa;
  border-radius: 50%;
  background-position: center center;
  background-size: 40%;
  width: 10vw;
  height: 10vw;
  max-width: 79px;
  max-height: 79px;
  transform: rotate(180deg);
}
