@charset "UTF-8";
@import "mixin", "base", "loading";

/* ==============================
		  common.scss
================================= */

/*---------------------------------------------------------
	全ページ共通スタイル
---------------------------------------------------------*/
* {
  box-sizing: border-box;
}

body {
  font-family: $jp_font;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: “palt” 1;
  font-stretch: 95%;
  line-height: 1.9;
  color: $black;
  text-align: justify;
  letter-spacing: .05em;
  background-color: $white;
  line-break: normal;

  font-size: 14px;

  @include mq(m) {
    font-size: 16px;
    overflow-x: hidden;
  }

}

body.android {
  font-family: "Noto Serif JP", serif;
  font-weight: 400;
}




.inview_box {
  opacity: 0;
  transition: all 1000ms ease;
  position: relative;
  z-index: 1;
  top: 50px;
}

.inview_box_on {
  opacity: 1;
  top: 0px;
}

.mainWidth {}

.mainInner {
  padding: 0 5%;
}

/* responsive */
.sp-inline,
.sp-block {
  display: none;
}

@include mq(s) {
  .sp-none {
    display: none;
  }

  .sp-inline {
    display: inline;
  }

  .sp-block {
    display: block;
  }
}

.en {
  font-family: $en_font;
}

// ==============================================
//                 ヘッダー
// ==============================================

#pageHeader {
  width: 100%;
  position: absolute;
  z-index: 20;
}

.menu {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;

  @include mq(l) {
    top: 28px;
    left: 28px;
  }

  &.scroll {}

  .p-hamburger__line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 34px;
    height: 2px;
    background-color: $white;
    transition: all .2s ease;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
      top: -9px;
      // transition: all .3s ease;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
      top: 9px;
      // transition: all .3s ease;
    }
  }

  &[aria-expanded="true"] {
    &.scroll {
      background: inherit;
    }

    .p-hamburger__line {
      background-color: inherit !important;

      &::before {
        top: 0;
        background-color: $black;
        transition: all .3s ease;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        background-color: $black;
        transition: all .3s ease;
        transform: rotate(-45deg);
      }

    }

  }

}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 5 !important;
  background: $white;
  color: $black;
  overflow: hidden;
  transition: all .6s ease-in;
  opacity: 0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transform-origin: right top;
  display: flex;
  transform: translateY(-100vh);

  &__list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 0 0 0 32px;
    flex-flow: column;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    top: -1rem;

    @include mq(m) {
      top: 0rem;
    }


    .headline_sec::before {
      font-size: 24px;

      @include mq(l) {
        font-size: 38px;
      }
    }

    .langage {
      position: relative;
      top: 0;
      right: 0;
      display: flex;
      margin-bottom: 24px;
      margin-top: 8px;
      width: calc(100% - 32px);
      justify-content: center;

      li {
        &:not(:last-child) a {
          border-right: 1px solid $black;
        }

        a {
          color: $black;
          display: inline;

        }
      }

    }

    &__items {
      display: none;

      &.animated {
        display: block;
      }

      a {
        display: block;
        width: calc(100vw - 3.2rem);
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        color: $black;


        &::after {
          content: "";
          background-image: url(../img/common/icons/menuarrow.svg);
          width: 18px;
          height: 18px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right center;

        }
      }



      &:nth-child(1) {
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-delay: 75ms;
      }

      &:nth-child(3) {
        animation-delay: 150ms;
      }

      &:nth-child(4) {
        animation-delay: 225ms;
      }

      &:nth-child(5) {
        animation-delay: 300ms;
      }

      &:nth-child(6) {
        animation-delay: 375ms;
      }

      &:nth-child(7) {
        animation-delay: 450ms;
      }

    }
  }

  &__blank {
    margin-top: 45px;

    @include mq(xl) {
      // width: 250px;
      margin: 0;
    }

    a {
      justify-content: space-between;
      display: flex;
      background: #005bce;
      color: $white;
      width: calc(100vw - 64px);
      align-items: center;

      @include mq(l) {
        align-items: center;
        width: 250px;
        height: 70px;
        @include transition(all, 0.5s);
      }

      &:hover {
        @include mq(l) {
          transform: translateX(70px);
        }
      }


      &.recruit {
        background: #46a5d6;
        margin-top: 16px;

        @include mq(l) {
          margin-top: 0;
        }

        &::after {
          background-color: #58acd7;
        }

      }

      &::after {
        content: "";
        padding: 0;
        width: 70px;
        height: 70px;
        background-color: #0f65d2;
        background-image: url(../img/common/icons/arrow.svg);
        background-size: 38px;
        background-position: center center;
        background-repeat: no-repeat;

        @include mq(l) {
          padding: 0;
        }
      }
    }

    &__link {
      padding: 16px 8px;
      width: calc(100% - 70px);
      text-align: center;

    }
  }

  .reservation-block {
    width: 90%;
    // padding: 24px 25px 24px 60px;
    padding: 24px 25px;
    justify-content: center;

    @include mq(m) {
      padding: 18px 32px;
      width: auto;
    }
  }
}

.langage {
  position: absolute;
  top: 40px;
  right: 60px;
  display: none;
  font-size: 12px;

  @include mq(m) {
    display: flex;
  }

  li {
    a {
      color: $white;
      padding: 0 1em;
    }

    &:not(:last-child) a {
      border-right: 1px solid $white;

    }

  }
}

body.open {
  overflow: hidden;

  .nav {
    opacity: 1 !important;
    transition: all .6s ease-in;
    transform: translateY(0);
    animation-delay: .3s;

    &__list {}

    .p-drawer {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transform: translateX(100%);
      transition: transform .3s ease-in-out;
      transform: translateX(0);
    }
  }
}

.u-visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
}

// ==============================================
//                 
// ==============================================
.reservation-block {
  padding: 17px 0;
  justify-content: center;
  background: $black;
  color: $white;
  position: relative;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 12;
  left: 0;
  width: 100%;
  cursor: pointer;
  cursor: hand;

  @include mq(m) {
    padding: 18px 32px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: auto;
  }

  &::after {
    position: absolute;
    top: calc(50% - 4px);
    right: 24px;
    content: "";
    width: 8px;
    height: 8px;
    display: none;
    border-top: 1px solid $white;
    border-right: 1px solid $white;
    transform: rotate(45deg);
    transition: all .3s ease;
  }

  .icon {
    width: 20px;
    height: 18px;
    margin-right: 1em;
    position: relative;
    top: -2px;
  }
}

// ==============================================
//                 section-access
// ==============================================

.section-access {
  background-color: $gray_a;
  color: $white;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;

  @include mq(m) {
    flex-flow: row;
  }

  .headline_sec.break {
    padding-top: 72px;
    margin-bottom: 29px;
  }

  &__iframe {
    overflow: hidden;

    #sample {
      width: 100vw;
      height: 100vw;
      filter: grayscale(100%);
      transition: all .3s ease;

      @include mq(m) {
        width: 50vw;
        height: 100%;
      }

      &:hover {
        filter: grayscale(0%);
      }
    }
  }

  &__text {
    padding: 36px 16px 36px 60px;

    @include mq(m) {
      padding: 63px 54px;
      width: 50%;
    }

    &__box {
      margin-bottom: 12px;
    }

    .s-size {
      font-size: 10px;
      margin-bottom: 32px;

      @include mq(m) {
        font-size: 15px;
      }
    }

    .m-size {
      font-size: .8em;

      @include mq(m) {
        font-size: 13px;
      }
    }

    .l-size {
      font-size: 1.2em;

      @include mq(m) {
        font-size: 20px;
      }

      .s-size {
        margin-left: 1em;

        @include mq(m) {
          font-size: 12px;
        }
      }
    }

    .fb {
      font-weight: 700;
    }
  }
}

.footer_img {
  background-image: url(../img/home/footer_img@2x.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 37vw;

  @include mq(m) {
    height: 530px;
  }
}

// ==============================================
//                 footer
// ==============================================

.footer {
  background-color: $black;
  color: $white;
  padding: 45px 32px 90px;

  @include mq(m) {
    padding: 54px 32px 120px;
  }

  &__inner {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 1332px;
    margin: 0 auto;

    @include mq(m) {
      flex-flow: row;
      justify-content: space-between;
    }
  }

  &__logo {
    margin-bottom: .5em;

    .logo {
      fill: $white;
      width: 30vw;
      max-width: 195px;
      height: 14vw;
      max-height: 81px;
    }
  }

  &__info {
    font-size: 12px;

    @include mq(m) {
      padding-top: 7px;
    }

    .adress {
      display: inline;

      @include mq(m) {
        display: block;
      }

    }
  }

  &__menu {
    display: none;
    font-size: 12px;

    a {
      color: $white;
      position: relative;
      opacity: 1;
      transition: all .3s ease;

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        top: 120%;
        left: 0;
        height: 1px;
        background: $white;
        transition: all .3s ease;
        transform: scaleX(0);
        transform-origin: left;
      }



      &:hover {
        opacity: .8;

        &::after {
          transform: scaleX(1);
        }
      }
    }

    @include mq(m) {
      padding-top: 7px;
      display: block;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      width: 210px;
      flex-flow: column;
      justify-content: space-around;

      @include mq(l) {
        justify-content: space-between;
        flex-flow: row;
        min-width: 500px;
        width: 44vw;
        max-width: 568px;
      }
    }
  }

  &__copy {
    font-size: 11px;

    @include mq(m) {
      padding-top: 7px;
    }
  }






}

.bottom {
  padding: 16px 0;
  background: #333;
  color: #fafafa;
  text-align: center;
}



// ==============================================
//                 common_btn
// ==============================================
.btn_cen {
  text-align: center;
}

.common_btn01 {
  position: relative;
  font-size: 16px;
  border: 1px solid $black;
  color: $black;
  text-align: center;
  padding: 16px 40px 16px 32px;
  transition: all .3s ease;
  display: inline-block;
  z-index: 1;

  &::after {
    position: absolute;
    top: calc(50% - 4px);
    right: 10px;
    content: "";
    width: 8px;
    height: 8px;
    display: block;
    border-top: 1px solid $black;
    border-right: 1px solid $black;
    transform: rotate(45deg);
    transition: all .3s ease;
  }

  &::before {
    transition: transform .3s ease;
    content: "";
    width: 100%;
    height: 100%;
    background: $black;
    transform: scaleX(0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &:hover {
    color: $white;


    &::after {
      border-top: 1px solid $white;
      border-right: 1px solid $white;
    }

    &::before {
      transform: scaleX(1);
    }
  }


  &.white {
    border: 1px solid $white;
    color: $white;

    &::after {
      border-top: 1px solid $white;
      border-right: 1px solid $white;
    }

    &::before {
      background: $white;
    }

    &:hover {
      color: $black;

      &::after {
        border-top: 1px solid $black;
        border-right: 1px solid $black;
      }
    }
  }
}

.common_btn02 {
  position: relative;
  font-size: 16px;
  border: 1px solid $black;
  color: $black;
  text-align: center;
  padding: 16px 0 16px 32px;
  transition: all .3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 240px;
  margin: 0 auto;
  z-index: 1;
  background-color: $white;

  @include mq(m) {
    width: 220px;
  }

  &::before {
    transition: transform .3s ease;
    content: "";
    width: 100%;
    height: 100%;
    background: $black;
    transform: scaleX(0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .toggle {
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    transition: all .3s ease;
    position: relative;

    &::before {
      content: "";
      width: 20px;
      height: 1px;
      display: block;
      position: absolute;
      background: $black;
      top: 50%;
      left: 10px;
      transition: all .3s ease;
    }

    &::after {
      content: "";
      width: 20px;
      height: 1px;
      display: block;
      position: absolute;
      background: $black;
      top: 50%;
      left: 10px;
      transform: rotate(90deg);
      transition: all .3s ease;
    }

    &.on {
      &::before {}

      &::after {
        transform: rotate(0);
      }
    }
  }

  &:hover {
    color: $white;

    &::before {
      transform: scaleX(1);
    }

    .toggle {
      &::before {
        background: $white;
      }

      &::after {
        background: $white;
        transform: rotate(0);
      }

      &.on {
        &::before {}

        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }


  &.white {
    border: 1px solid $white;
    color: $white;

    &::after {
      border-top: 1px solid $white;
      border-right: 1px solid $white;
    }

    &::before {
      background: $white;
    }

    &:hover {
      color: $black;

      &::after {
        border-top: 1px solid $black;
        border-right: 1px solid $black;
      }
    }
  }
}

// ==============================================
//                 pop_tenawan!
// ==============================================
.pop_tenawan {
  position: fixed;
  background: rgba(25, 25, 25, .9);
  z-index: 190;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  .headline_sec.break {
    position: relative;
    padding-top: 30px;
    margin-bottom: 8px;

    @include mq(l) {
      margin-bottom: 100px;
      padding-top: 69px;
    }
  }

  form {
    max-width: 788px;
    width: 100%;
    padding: 24px;
  }

  .tenawan__form {
    display: flex;
  }


  &__headline {}

  &__form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // flex-flow:column;
    flex-wrap: wrap;

    @include mq(l) {
      // flex-flow:row;
      width: 100%;
      // justify-content: space-between;
    }

    &__list {
      width: 85px;
      margin-bottom: 16px;

      @include mq(l) {
        width: 103px;
        margin-bottom: 8px;
      }

      &:first-child {
        width: 100%;
        margin-bottom: 16px;

        @include mq(l) {
          width: 280px;
          margin-bottom: 8px;
        }
      }

      select {
        font-family: $jp_font;
        font-size: 18px;
        padding: 4px 0 8px;
        min-width: 3em;

        @include mq(l) {
          width: 80px;
          margin-right: 6px;
        }
      }

      .ym {
        width: 50%;

        @include mq(l) {
          width: 180px;
          margin-right: 5px;
        }

      }

      .d {
        @include mq(l) {
          width: 80px;
          margin-right: 0;
        }

      }
    }

    &__link {

      a {
        color: #fff;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          display: inline-block;
          margin-right: 8px;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent #ffffff;
        }
      }
    }

    &__btns {
      @include mq(l) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;
      }
    }

    &__link {}


  }

  .pop_tenawan__search {
    display: flex;
    justify-content: center;

    @include mq(l) {
      margin-top: 30px;
      margin-bottom: 70px;
    }

  }

  .common_btn01 {
    width: 100%;
    margin-bottom: 8px;
    font-family: $jp_font;

    @include mq(l) {
      max-width: 240px;

    }

    img {}

    &:not(:hover) {
      img {
        filter: saturate(0%) brightness(9999%);
      }
    }
  }

  #tenawan_close {
    position: relative;
    position: fixed;
    top: 20px;
    right: 20px;

    width: 40px;
    height: 40px;
    cursor: pointer;
    cursor: hand;

    @include mq(l) {
      top: 40px;
      right: 80px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 40px;
      background: $white;
      top: 50%;
      left: 0;
      transform: rotate(45deg);
    }

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 40px;
      background: $white;
      top: 50%;
      left: 0;
      transform: rotate(135deg);
    }
  }
}


// ==============================================
//                 room_headline
// ==============================================
.room_headline {
  position: relative;
  font-size: 25px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  margin: 110px 0 0;
  flex-flow: column;
  align-items: center;
  line-height: 1;
  z-index: 2;

  @include mq(m) {
    font-size: 30px;
  }

  &::before {
    content: "type.";
    position: absolute;
    display: inline-block;
    top: -126px;
    left: calc(50% - 1em);
    width: auto;

    @include mq(m) {
      left: calc(50% - 1em);
      width: 100%;
    }

  }

  &::after {
    content: "00";
    font-family: $en_font;
    position: absolute;
    display: inline-block;
    top: -106px;
    left: calc(50% - .5em);
    font-size: 100px;
    width: auto;

    @include mq(m) {
      font-size: 113px;
      left: calc(50% - .5em);
      width: 100%;
    }
  }

  &.first::after {
    content: "01";
  }

  &.second::after {
    content: "02";
  }

  &.third::after {
    content: "03";
  }

  p:not(.kana) {
    position: relative;

    &::before {
      content: "";
      width: 23px;
      height: 1px;
      display: block;
      position: absolute;
      top: -80px;
      left: calc(50% - 11px);
      background-color: $black;

      @include mq(m) {}
    }
  }

  .kana {
    margin-top: .5em;
    font-family: $jp_font;
    font-size: 10px;

    @include mq(m) {
      font-size: 14px;
    }
  }
}

// ==============================================
//                 左の追従
// ==============================================
.left-fix {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  font-size: 12px;
  line-height: 1.333;
  position: fixed;
  top: 30vh;
  left: 21px;
  color: $white;
  transition: all .3s ease;
  z-index: 1;

  @include mq(xl) {
    left: 42px;
  }

  .inline {
    display: inline;

    @include mq(m) {
      display: block;
    }
  }
}

// ==============================================
//                 トップに戻る
// ==============================================
.pagetop {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: $white;
  border-radius: 50%;
  overflow: hidden;
  z-index: 11;

  @include mq(m) {
    width: 80px;
    height: 80px;
  }

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    border-left: 2px solid $black;
    border-top: 2px solid $black;
    transform: rotate(45deg);
    position: relative;
    top: 2px;

    @include mq(m) {
      width: 25px;
      height: 25px;
      top: 5px;
    }
  }

}

// ==============================================
//                 見出し改行なし
// ==============================================
.headline_sec {
  font-size: 12px;
  font-weight: 400;

  @include mq(m) {
    font-size: 18px;
  }


  &::before {
    content: attr(data-ruby);
    font-family: $en_font;
    font-size: 32px;
    font-weight: 400;
    margin-right: 7px;

    @include mq(m) {
      margin-right: 29px;
    }

    @include mq(m) {
      font-size: 64px;
    }
  }

  // ==============================================
  //                 見出し改行あり
  // ==============================================
  &.break {
    position: relative;
    padding-top: 32px;

    &::before {
      position: absolute;
      top: -15px;
      left: 0;

      @include mq(m) {
        top: -55px;
      }

      @include mq(l) {
        top: -15px;
      }
    }

  }

}




.section-inner {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 820px;

  @include mq(xl) {
    max-width: 1100px;
  }

}


.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: 104px;
  color: $black;

  &::before {
    content: "";
    width: 32px;
    height: 1px;
    background: $white;
    transform: rotate(45deg);
    position: absolute;
    top: auto;
    left: auto;
  }

  &::after {
    content: "";
    width: 32px;
    height: 1px;
    background: $white;
    transform: rotate(-45deg);
    position: absolute;
    top: auto;
    left: auto;
  }
}



.swiper-container {
  width: 100%;
  overflow: hidden;
  top: -90px;
  max-height: 700px;

  @include mq(m) {
    top: 0;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(../img/common/arrow01.svg);
    background-color: $white;
    border-radius: 50%;
    background-position: center center;
    background-size: 40%;
    width: 10vw;
    height: 10vw;
    max-width: 79px;
    max-height: 79px;

  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(../img/common/arrow01.svg);
    background-color: $white;
    border-radius: 50%;
    background-position: center center;
    background-size: 40%;
    width: 10vw;
    height: 10vw;
    max-width: 79px;
    max-height: 79px;
    transform: rotate(180deg);

  }


}
