@import "mixin";
$black: #1c1c1c;
$gray_a: #3c3c3f;
$gray_b: #2f2f2f;
$white: #fafafa;

$jp_font: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
$en_font: 'Sorts Mill Goudy', serif;

$breakpoints: (
  's' : 'screen and (min-width: 560px)',
  'm': 'screen and (min-width:  960px)',
  'l' : 'screen and (min-width: 1120px)',
  'xl': 'screen and (min-width: 1280px)',
  'xxl': 'screen and (min-width: 1980px)',
);
// @include mq(m){}